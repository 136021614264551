import React from 'react';

import './App.css';
import { Container, Spinner } from 'react-bootstrap';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import Footer from './components/Footer';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import { useFetchUser } from './hooks/useFetchUser';
import { AuthorizedRoute, PrivateRoute, ROUTES_LIST } from './routes';
import UserContext from './user-context';
import NotFound from './views/NotFound';

const App = React.memo(() => {
  const user = useFetchUser();

  return (
    <UserContext.Provider value={user}>
      <React.StrictMode>
        <BrowserRouter>
          <Container fluid>
            {user.isFetching && (
              <div className="app-wrapper d-flex justify-content-center align-items-center">
                <Spinner
                  animation="grow"
                  variant="primary"
                  role="status"
                  as="div"
                  className="preloader"
                >
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            )}

            {!user.isFetching && (
              <>
                {user.user && <Sidebar />}

                <div className={`app-wrapper ${user.user ? 'app-wrapper-content' : ''}`}>
                  <Header />
                  <Routes>
                    {ROUTES_LIST.map(({ name, isProtected, ...route }) =>
                      isProtected ? (
                        <Route key={`private-${name}`} element={<PrivateRoute />}>
                          <Route key={name} {...route} />
                        </Route>
                      ) : (
                        <Route key={`private-${name}`} element={<AuthorizedRoute />}>
                          <Route key={name} {...route} />
                        </Route>
                      ),
                    )}

                    <Route path="*" element={<NotFound />} />
                  </Routes>
                </div>

                <Footer />

                <ToastContainer
                  position="bottom-right"
                  autoClose={3000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  draggable={false}
                  pauseOnHover
                  pauseOnFocusLoss={false}
                  theme="colored"
                />
              </>
            )}
          </Container>
        </BrowserRouter>
      </React.StrictMode>
    </UserContext.Provider>
  );
});

export default App;
