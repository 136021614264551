import React, { useContext } from 'react';

import { Navigate, Outlet, RouteProps, useLocation } from 'react-router-dom';

import UserContext from './user-context';
import EditProfile from './views/EditProfile';
import Login from './views/Login';
import MobilityCoach from './views/MobilityCoach';
import PaymentSuccessful from './views/PaymentSuccessful';
import Profile from './views/Profile';

type IRoute = RouteProps & {
  name: string;
  isProtected: boolean;
};

export const ROUTES_LIST: IRoute[] = [
  {
    path: '/',
    element: <Profile />,
    name: 'profile',
    isProtected: true,
  },
  {
    path: '/edit',
    element: <EditProfile />,
    name: 'profile',
    isProtected: true,
  },
  {
    path: '/mobility-coach',
    element: <MobilityCoach />,
    name: 'profile',
    isProtected: true,
  },
  {
    path: '/login',
    element: <Login />,
    name: 'login',
    isProtected: false,
  },
  {
    path: '/payment/successful',
    element: <PaymentSuccessful />,
    name: 'paymentSuccessful',
    isProtected: false,
  },
];

export function PrivateRoute() {
  const { user } = useContext(UserContext);

  return user ? <Outlet /> : <Navigate to="/login" />;
}

export function AuthorizedRoute() {
  const { user } = useContext(UserContext);
  const location = useLocation();

  const payment = ROUTES_LIST.find(({ name }) => name === 'paymentSuccessful');

  if (payment && location?.pathname === payment.path) {
    return <Outlet />;
  }

  return user ? <Navigate to="/" /> : <Outlet />;
}
