import React from 'react';

import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import SubmitButton from '../../../../components/SubmitButton';
import TextField from '../../../../components/TextField';
import { IVerificationCodeForm } from '../../../../types/security.type';

interface IProps {
  isProcess: boolean;
  onSubmit: (values: IVerificationCodeForm) => void;
}

const VerificationCodeForm: React.FC<IProps> = React.memo(({ isProcess, onSubmit }) => (
  <Formik
    initialValues={{
      code: '',
    }}
    validationSchema={Yup.object({
      code: Yup.string().required('Required'),
    })}
    onSubmit={onSubmit}
  >
    {(formik) => (
      <Form className="card">
        <div className="headline">Validate verification code</div>

        <TextField
          type="password"
          label="Verification code"
          id="code"
          {...formik.getFieldProps('code')}
        />

        <SubmitButton
          label="Validate verification code"
          isProcess={isProcess}
          isDisabled={!formik.dirty || !formik.isValid || isProcess}
        />
      </Form>
    )}
  </Formik>
));

VerificationCodeForm.propTypes = {
  isProcess: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default VerificationCodeForm;
