import React from 'react';

import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import SubmitButton from '../../../../components/SubmitButton';
import TextField from '../../../../components/TextField';
import { IPhoneForm } from '../../../../types/security.type';

interface IProps {
  isProcess: boolean;
  onSubmit: (values: IPhoneForm) => void;
}

const PhoneForm: React.FC<IProps> = React.memo(({ isProcess, onSubmit }) => (
  <Formik
    initialValues={{
      phone: '',
    }}
    validationSchema={Yup.object({
      phone: Yup.string()
        .matches(/^\+[\d]{9,15}$/, 'Phone number is not valid. Valid example: +33123456789')
        .required('Required'),
    })}
    onSubmit={onSubmit}
  >
    {(formik) => (
      <Form className="card">
        <div className="headline">Verification code</div>

        <TextField
          type="text"
          label="Pour vous connecter à votre compte, merci de saisir votre numéro de téléphone (au format +33 6 XXXXXXXX), puis de renseigner le code de vérification que vous allez recevoir par SMS."
          id="phone"
          {...formik.getFieldProps('phone')}
        />

        <SubmitButton
          label="Send verification code"
          isProcess={isProcess}
          isDisabled={!formik.dirty || !formik.isValid || isProcess}
        />
      </Form>
    )}
  </Formik>
));

PhoneForm.propTypes = {
  isProcess: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default PhoneForm;
