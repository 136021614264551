import { ISelectItem } from '../components/SelectField/types';

export const LANGUAGES: ISelectItem[] = [
  { value: 'EN', label: 'English' },
  { value: 'FR', label: 'French' },
  { value: 'DE', label: 'German' },
  { value: 'IT', label: 'Italian' },
  { value: 'N/A', label: 'Other' },
  { value: 'ES', label: 'Spanish' },
];
