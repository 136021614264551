import PropTypes from 'prop-types';

export const UserShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  firstname: PropTypes.string.isRequired,
  lastname: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  linkedin: PropTypes.string.isRequired,
  birthday: PropTypes.string.isRequired,
  available: PropTypes.number.isRequired,
  date_available: PropTypes.string.isRequired,
  languages: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  address_secondary: PropTypes.string.isRequired,
  postalcode: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  email_secondary: PropTypes.string.isRequired,
  phone_professional: PropTypes.string.isRequired,
  picture: PropTypes.string,
  cv: PropTypes.string,
  cv_images: PropTypes.arrayOf(PropTypes.string.isRequired),
  cv_fr: PropTypes.string,
  cv_fr_images: PropTypes.arrayOf(PropTypes.string.isRequired),
  is_coaching_paid: PropTypes.bool.isRequired,
});
