import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { Col, Tab, Tabs } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { uploadCv } from '../../../../api/user';
import { UserShape } from '../../../../shapes/user.shape';
import { IUserCv } from '../../../../types/user.type';
import { IProps } from '../../types';
import CvForm from '../CvForm';

import './styles.css';

const FILE_SIZE = 20 * 1024 * 1024;

const SUPPORTED_FORMATS = [
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingm',
  'application/zip',
];

const Cv: React.FC<IProps> = React.memo(({ user, onSave }) => {
  const [isProcess, setIsProcess] = useState<boolean>(false);

  const handleSubmit = React.useCallback(
    async (file: File, version?: string) => {
      setIsProcess(true);

      try {
        const response = await uploadCv(file, version);

        onSave(response);
      } catch {
        toast.error('Error during uploading CV');
      } finally {
        setIsProcess(false);
      }
    },
    [onSave],
  );

  const handleCv = async (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    version?: string,
  ) => {
    const target = event.currentTarget as HTMLInputElement;

    if (!target.files?.length) {
      return;
    }

    const cv = target.files[0];

    if (!SUPPORTED_FORMATS.includes(cv.type)) {
      toast.error('Unsupported format.');

      return;
    }

    if (cv.size > FILE_SIZE) {
      toast.error('File too large.');

      return;
    }

    await handleSubmit(cv, version);

    target.value = '';
  };

  return (
    <div className="card upload-wrapper">
      <Col lg={12} md={12} className="cv-tabs">
        <Tabs defaultActiveKey="english">
          <Tab eventKey="english" title="English" disabled={isProcess}>
            <CvForm
              name="cv"
              isProcess={isProcess}
              onChange={(event) => handleCv(event)}
              images={user.cv_images}
            />
          </Tab>

          <Tab eventKey="french" title="French" disabled={isProcess}>
            <CvForm
              name="cv_fr"
              isProcess={isProcess}
              onChange={(event) => handleCv(event, IUserCv.french)}
              images={user.cv_fr_images}
            />
          </Tab>
        </Tabs>
      </Col>
    </div>
  );
});

Cv.propTypes = {
  user: UserShape.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default Cv;
