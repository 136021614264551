import React from 'react';

import { Card } from 'react-bootstrap';

import PageWrapper from '../../components/PageWrapper';

const NotFound: React.FC = React.memo(() => (
  <PageWrapper>
    <Card className="align-items-center p-5">
      <h1>Page is not found</h1>
    </Card>
  </PageWrapper>
));

export default NotFound;
