import React from 'react';

import { Card } from 'react-bootstrap';
import { FaCircleCheck } from 'react-icons/fa6';

import PageWrapper from '../../components/PageWrapper';

const PaymentSuccessful = React.memo(() => (
  <PageWrapper>
    <Card className="align-items-center">
      <Card.Body>
        <Card.Title className="d-flex align-items-center mb-4 justify-content-center">
          <FaCircleCheck size={60} className="me-3 text-success" />

          <h1 className="text-success m-0">Congratulations!</h1>
        </Card.Title>

        <h3>Your payment was successful and we will contact you shortly!</h3>
      </Card.Body>
    </Card>
  </PageWrapper>
));

export default PaymentSuccessful;
