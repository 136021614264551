import React, { useContext, useMemo, useState } from 'react';

import { Button, Container, Modal } from 'react-bootstrap';

import UserContext from '../../../../user-context';

const ProgramRegister = React.memo(() => {
  const [isShow, setShow] = useState(false);
  const { user } = useContext(UserContext);

  const paymentUrl = useMemo(() => {
    const url = process.env.REACT_APP_PAYMENT_URL;

    if (!url || !user) {
      return null;
    }

    return `${url}?prefilled_email=${user!.email}`;
  }, [user]);

  return (
    <>
      <Button
        type="button"
        variant="outline-primary"
        onClick={() => setShow(true)}
        className="me-3"
      >
        Je m’inscris au programme
      </Button>

      <Modal
        show={isShow}
        onHide={() => setShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>S`INSCRIRE AU PROGRAMME</Modal.Title>
        </Modal.Header>

        <Modal.Body className="text-center">
          <Container>
            <div>
              Nous sommes ravis de vous compter très bientôt au sein du programme Mon Coach Mobilité
              par KINT, merci pour votre confiance.
            </div>
          </Container>
        </Modal.Body>

        <Modal.Footer>
          {paymentUrl && (
            <Button
              variant="primary"
              onClick={() => {
                window.location.href = paymentUrl;
              }}
            >
              Procéder au paiement
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
});

export default ProgramRegister;
