import React from 'react';

import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { Col, Spinner } from 'react-bootstrap';

import FileField from '../../../../components/FileField';
import './styles.css';

interface IProps {
  name: string;
  isProcess: boolean;
  onChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    version?: string,
  ) => void;
  images?: string[] | null;
}

const CvForm: React.FC<IProps> = React.memo(({ name, isProcess, onChange, images }) => {
  const renderPreview = React.useMemo(() => {
    if (!images?.length) {
      return <div className="text-center">The file has not been converted yet</div>;
    }

    return (
      <div className="cv-images">
        {images.map((image) => (
          <img key={image} src={`${image}?${Date.now()}`} alt={image} />
        ))}
      </div>
    );
  }, [images]);

  return (
    <>
      <Col lg={12} md={12}>
        <Formik initialValues={{ [name]: '' }} onSubmit={() => {}}>
          {(formik) => (
            <>
              <div className={`upload-cv ${isProcess ? 'd-none' : ''}`}>
                <FileField
                  id={name}
                  label=""
                  onlyButton
                  {...formik.getFieldProps(name)}
                  disabled={isProcess}
                  onChange={onChange}
                />
              </div>

              {isProcess && (
                <Col className="mt-3 mb-3 d-flex">
                  <Spinner animation="grow" variant="primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </Col>
              )}
            </>
          )}
        </Formik>
      </Col>

      <Col lg={12} md={12} className="mt-3">
        {renderPreview}
      </Col>
    </>
  );
});

CvForm.propTypes = {
  name: PropTypes.string.isRequired,
  isProcess: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  images: PropTypes.array,
};

export default CvForm;
