import { ApiHelper } from '../helpers/api.helper';
import { IAccessToken, IPhoneForm, IValidationCodeForm } from '../types/security.type';
import { IStatusResponse } from '../types/status-response.type';

const url = 'security/auth';

export const sendVerificationCode = async (params: IPhoneForm): Promise<IStatusResponse> => {
  const response = await fetch(ApiHelper.makeUrl(`${url}/step-one`), {
    method: 'POST',
    body: JSON.stringify(params),
    ...ApiHelper.makeOptions(),
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message);
  }

  return data;
};

export const validateVerificationCode = async (
  params: IValidationCodeForm,
): Promise<IAccessToken> => {
  const response = await fetch(ApiHelper.makeUrl(`${url}/step-two`), {
    method: 'POST',
    body: JSON.stringify(params),
    ...ApiHelper.makeOptions(),
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message);
  }

  return data;
};
