import React, { useState } from 'react';

import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { IProfessionalForm } from './types';
import validation from './validation';
import { updateUser } from '../../../../api/user';
import SelectField from '../../../../components/SelectField';
import SubmitButton from '../../../../components/SubmitButton';
import TextField from '../../../../components/TextField';
import { COUNTRIES } from '../../../../constants/countries';
import { LANGUAGES } from '../../../../constants/languages';
import { UserShape } from '../../../../shapes/user.shape';
import { IProps } from '../../types';

const ProfessionalForm: React.FC<IProps> = React.memo(({ user, onSave }) => {
  const [isProcess, setIsProcess] = useState<boolean>(false);

  const handleSubmit = React.useCallback(
    async (values: IProfessionalForm) => {
      setIsProcess(true);

      try {
        const response = await updateUser({
          ...user,
          ...values,
        });

        onSave(response);
      } catch {
        toast.error('Error saving profile');
      } finally {
        setIsProcess(false);
      }
    },
    [onSave, user],
  );

  return (
    <Formik
      initialValues={{
        languages: user.languages ?? '',
        address: user.address ?? '',
        address_secondary: user.address_secondary ?? '',
        postalcode: user.postalcode ?? '',
        city: user.city ?? '',
        country: user.country ?? '',
        email_secondary: user.email_secondary ?? '',
        phone_professional: user.phone_professional ?? '',
      }}
      validationSchema={validation}
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <Form className="card">
          <Col lg={12} md={12} className="mt-3">
            <div className="headline">CAPACITIES:</div>
          </Col>

          <Col lg={12} md={12}>
            <Row>
              <Col lg={6} md={6}>
                <SelectField
                  label="Primary language"
                  id="languages"
                  options={LANGUAGES}
                  {...formik.getFieldProps('languages')}
                />
              </Col>
            </Row>
          </Col>

          <Col lg={12} md={12} className="mt-3">
            <div className="headline">ADDRESS:</div>
          </Col>

          <Col lg={12} md={12}>
            <Row>
              <Col lg={8} md={8}>
                <TextField
                  type="text"
                  label="Address 1"
                  id="address"
                  {...formik.getFieldProps('address')}
                />
              </Col>

              <Col lg={4} md={4}>
                <TextField
                  type="text"
                  label="Address 2"
                  id="address_secondary"
                  {...formik.getFieldProps('address_secondary')}
                />
              </Col>
            </Row>

            <Row>
              <Col lg={4} md={4}>
                <TextField
                  type="text"
                  label="Postal code"
                  id="postalcode"
                  {...formik.getFieldProps('postalcode')}
                />
              </Col>

              <Col lg={4} md={4}>
                <TextField type="text" label="City" id="city" {...formik.getFieldProps('city')} />
              </Col>

              <Col lg={4} md={4}>
                <SelectField
                  label="Country"
                  id="country"
                  options={COUNTRIES}
                  {...formik.getFieldProps('country')}
                />
              </Col>
            </Row>

            <Row>
              <Col lg={4} md={4}>
                <TextField
                  type="text"
                  label="Email 2"
                  id="email_secondary"
                  {...formik.getFieldProps('email_secondary')}
                />
              </Col>

              <Col lg={4} md={4}>
                <TextField
                  type="text"
                  label="Professional phone"
                  id="phone_professional"
                  {...formik.getFieldProps('phone_professional')}
                />
              </Col>
            </Row>
          </Col>

          <Col className="mt-3">
            <div className="d-inline-flex">
              <SubmitButton
                label="Save"
                isProcess={isProcess}
                isDisabled={!formik.isValid || isProcess}
              />
            </div>
          </Col>
        </Form>
      )}
    </Formik>
  );
});

ProfessionalForm.propTypes = {
  user: UserShape.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default ProfessionalForm;
