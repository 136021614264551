export type IUser = Record<
  | 'firstname'
  | 'lastname'
  | 'email'
  | 'phone'
  | 'linkedin'
  | 'birthday'
  | 'languages'
  | 'address'
  | 'address_secondary'
  | 'postalcode'
  | 'city'
  | 'country'
  | 'email_secondary'
  | 'phone_professional'
  | 'date_available',
  string
> & {
  id: number;
  available: number;
  picture?: string | null;
  cv?: string | null;
  cv_images?: string[] | null;
  cv_fr?: string | null;
  cv_fr_images?: string[] | null;
  is_coaching_paid: boolean;
};

export enum IUserCv {
  english = 'en',
  french = 'fr',
}
