import React, { useContext } from 'react';

import { Badge, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import Avatar from '../../components/Avatar';
import EditProfileButton from '../../components/Header/components/EditProfileButton';
import Tooltip from '../../components/Tooltip';
import { AVAILABLES } from '../../constants/availables';
import { COUNTRIES } from '../../constants/countries';
import { LANGUAGES } from '../../constants/languages';
import UserContext from '../../user-context';
import './styles.css';

const Profile = React.memo(() => {
  const { user } = useContext(UserContext);

  const primaryLanguage = React.useMemo(() => {
    if (!user?.languages) {
      return null;
    }

    const language = LANGUAGES.find((item) => item.value === user.languages);

    return language?.label ?? null;
  }, [user]);

  const userCountry = React.useMemo(() => {
    if (!user?.country) {
      return null;
    }

    const country = COUNTRIES.find((item) => item.value === user.country);

    return country?.label ?? null;
  }, [user]);

  const isAvailable = React.useMemo(() => user?.available, [user]);

  const available = React.useMemo(() => {
    if (!user) {
      return null;
    }

    const label = AVAILABLES.find((item) => item.value === user.available);

    return label?.label ?? null;
  }, [user]);

  if (!user) {
    return null;
  }

  return (
    <div className="d-flex justify-content-center p-3 profile-wrapper">
      <Col lg={12} md={12}>
        <div className="card">
          <Row className="mb-5">
            <Col lg={11} md={11} className="d-flex">
              <div className="me-3">
                <Avatar src={user.picture} />
              </div>

              <div>
                <div className="profile-name">
                  <span className="me-2">
                    {user.firstname} {user.lastname}
                  </span>

                  <Tooltip
                    id="available"
                    placement="bottom"
                    label={
                      isAvailable && user.date_available
                        ? `Available from : ${user.date_available}`
                        : ''
                    }
                  >
                    <Badge pill bg={isAvailable ? 'success' : 'danger'}>
                      {available}
                    </Badge>
                  </Tooltip>
                </div>

                <div>{user.email}</div>
                <div>{user.phone}</div>
                <div>{user.birthday}</div>

                {user.linkedin && (
                  <div>
                    <Link
                      to={user.linkedin}
                      className="profile-linkedin"
                      target="_blank"
                      rel="nofollow"
                    >
                      {user.linkedin}
                    </Link>
                  </div>
                )}
              </div>
            </Col>

            <Col lg={1} md={1} className="d-flex justify-content-end align-items-start">
              <Tooltip id="profile" label="Edit yout profile">
                <EditProfileButton size={28} />
              </Tooltip>
            </Col>
          </Row>

          {primaryLanguage && (
            <Row className="mb-4">
              <Col lg={12} md={12}>
                <div className="headline">CAPACITIES:</div>
                <div>{primaryLanguage}</div>
              </Col>
            </Row>
          )}

          <Row>
            <Col lg={12} md={12}>
              <div className="headline">ADDRESS:</div>

              <div>
                <u>Address</u> : {user.address}
              </div>

              <div>
                <u>Address secondary</u> : {user.address_secondary}
              </div>

              <div>
                <u>Postal code</u> : {user.postalcode}
              </div>

              <div>
                <u>City</u> : {user.city}
              </div>

              {user.country && (
                <div>
                  <u>Country</u> : {userCountry}
                </div>
              )}

              <div>
                <u>Email secondary</u> : {user.email_secondary}
              </div>

              <div>
                <u>Phone professional</u> : {user.phone_professional}
              </div>
            </Col>
          </Row>
        </div>
      </Col>
    </div>
  );
});

export default Profile;
