import React, { useContext } from 'react';

import './styles.css';
import { FaCircleCheck, FaMobileScreen } from 'react-icons/fa6';
import { FiHome } from 'react-icons/fi';
import { Link } from 'react-router-dom';

import UserContext from '../../user-context';
import Tooltip from '../Tooltip';

const Sidebar = React.memo(() => {
  const { user } = useContext(UserContext);

  return (
    <div className="sidebar">
      <div className="menu">
        <ul>
          <li>
            <Link to="/">
              <span className="title">My profile</span>
            </Link>

            <span className="icon-thumbnail">
              <FiHome />
            </span>
          </li>

          <li>
            <Link to="/mobility-coach">
              <span className="title">
                Mon Coach Mobilité
                {user?.is_coaching_paid && (
                  <Tooltip id="is_paid" placement="bottom" label="Paid">
                    <FaCircleCheck size={14} className="ms-1 me-1" color="green" />
                  </Tooltip>
                )}
              </span>
            </Link>

            <span className="icon-thumbnail">
              <FaMobileScreen />
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
});

export default Sidebar;
